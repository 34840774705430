import React from 'react';

const About = () => {
  return (
    <div className=" bg-gray-700 ">
      <div className="bg-white shadow-md rounded-lg  px-10 w-full">
        <h1 className="text-4xl font-bold mb-4 text-start">About Us</h1>
        <p className="text-lg mb-6">
          "Voice for Change" is a website that echoes the need for societal transformation, emphasizing changes that benefit society at large while also being dedicated to the upliftment of the Rongmei Naga tribe in particular, and the Naga communities in general, as well as the diverse communities of North East India. Published by the Rongmei People (Facebook Page), it serves as a powerful voice advocating for positive change and progress in the region.
        </p>
        <p className="text-lg mb-6">
          The website aims to inspire and engage a broad spectrum of contributors, including experts, teachers, leaders of civil society organizations, scholars, and especially the student community. By encouraging the creation of diverse content—such as research papers, articles, opinion pieces, book reviews, short stories, and poetry—Voice for Change fosters rich intellectual dialogue on critical issues. The journal motivates contributors to explore topics like North East Indian culture, societal shifts, and political issues to promote a more peaceful, tolerant, inclusive, secure, and sustainable society.
        </p>
        <p className="text-lg mb-6">
          The website envisions itself as a hub for knowledge dissemination and a catalyst for change, promoting informed discussions that contribute to the betterment of North East Indian societies. It aims to shed light on the changes needed to address societal challenges and drive progress. By actively engaging experts and the academic community, Voice for Change positions itself as a valuable resource that not only identifies issues but also offers potential solutions, guiding positive transformation in North East India.
        </p>
        <p className="text-lg mb-6">
          <p>The website envisions itself as a hub for knowledge dissemination and a catalyst for change, promoting informed discussions that contribute to the betterment of North East Indian societies. It aims to shed light on the changes needed to address societal challenges and drive progress. By actively engaging experts and the academic community, Voice for Change positions itself as a valuable resource that not only identifies issues but also offers potential solutions, guiding positive transformation in North East India.</p>
          <ul>
            <li>Encourage young people to pursue their dreams;</li>
            <li>Contribute to societal advancement, particularly through describing good practices and identifying necessary changes;
            </li>
            <li>Critique dominant views of North Eastern tribal social norms to identify weaknesses;
            </li>
            <li>Help tribal communities identify solutions to shared challenges.</li>
            <li></li>
          </ul>
        </p>
        {/* <p className="text-lg mb-6">
          p
        </p>
        <p className="text-lg mb-6">
          p
        </p>
        <p className="text-lg mb-6">
          p
        </p> */}
      </div>
    </div>
  );
};

export default About;